import React from "react"
import { Link } from "gatsby"
import { rhythm, scale } from "../utils/typography"

const Layout = ({ location, title, children }) => {
  // const rootPath = `${__PATH_PREFIX__}/`
  let header
  let currentPath = location.pathname

  header = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
    >
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
          display: "inline-block",
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
      <ul
        style={{
          display: "inline-flex",
          color: "lightcoral",
          marginTop: 0,
          marginLeft: "15px",
          listStyleType: "none",
          justifyContent: "space-between",
        }}
      >
        {/* <li style={{ marginRight: "10px" }}>
          <Link
            style={{
              fontWeight: currentPath.includes("thoughts") ? "bold" : "inherit",
              boxShadow: currentPath.includes("thoughts")
                ? "0 1px 0 0 currentColor"
                : "none",
            }}
            to="/thoughts"
          >
            Thoughts
          </Link>
        </li> */}
        <li>
          <Link
            style={{
              fontWeight: currentPath === "/contact" ? "bold" : "inherit",
              boxShadow:
                currentPath === "/contact" ? "0 1px 0 0 currentColor" : "none",
            }}
            to="/contact"
          >
            Contact
          </Link>
        </li>
      </ul>
    </div>
  )

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>

      <footer>© {new Date().getFullYear()}, ☕</footer>
    </div>
  )
}

export default Layout
